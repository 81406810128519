import { isMobile, isTablet } from "react-device-detect";

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

export const getContentModalWrapperDimension = (cssProp) => {
    const baseWidth = isTablet ? '100%' : isMobile ? '90%' : windowWidth < 1400 ? (windowHeight > 800 ? `${windowWidth * 0.95}px` : `${windowWidth * 0.85}px`) : `${windowWidth * 0.8}px`;
    const baseHeight = isMobile ? 100 : windowWidth < 1400 ? (windowHeight > 800 ? 92 : 105) : 100;
    const baseLeft = isTablet ? '-3%' : isMobile ? '0%' : windowWidth < 1400 ? (windowHeight > 800 ? '-190px' : 'auto') : '-14%';

    const dimensions = {
        width: baseWidth,
        height: baseHeight,
        left: baseLeft,
    };

    return dimensions[cssProp];
};

export const getRightCloseButton = () => {
    if (windowWidth > 1800 || isMobile) return '-2%';

    return '-3%';
}