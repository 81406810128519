import AdmiralTheme from '../../../web/src/themes/AdmiralTheme';
import AdmesTheme from '../../../web/src/themes/AdmesTheme';
import HriTheme from '../../../web/src/themes/HriTheme';
import MillelacsTheme from '../../../web/src/themes/MillelacsTheme';
import JamulTheme from '../../../web/src/themes/JamulTheme';
import LadyluckTheme from '../../../web/src/themes/LadyluckTheme';
import TiTheme from '../../../web/src/themes/TiTheme';
import SgaTheme from '../../../web/src/themes/SgaTheme';
export var themes = {
  admes: AdmesTheme,
  admiral: AdmiralTheme,
  hri: HriTheme,
  sga: SgaTheme,
  ti: TiTheme,
  millelacs: MillelacsTheme,
  jamul: JamulTheme,
  ladyluck: LadyluckTheme
};