import React from 'react';
import screens from 'shared/screens';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { pwaStyleAdapter } from '../../../utils/mobileSupported';
import ImageBoundsWrapper from '../../ImageBoundsWrapper';
import styled from 'styled-components';

const SettingsMenu = ({ showModal, themeContext }) => {
  return (
    <MenuButton className="proto" onClick={() => showModal(screens.Settings)}>
      <ImageBoundsWrapper
        source={assetSource(ASSET_KEY.MENU_HAMBURGER)}
        ibw={require(asset`images/top-bar-assets/menu-hamburger.ibw`)}
        style={pwaStyleAdapter(themeContext.NavigationBar.SettingIcon)}
      />
    </MenuButton>
  );
};

const MenuButton = styled.button`
  padding: 0;
  margin-left: 1rem;
  background: none;
  border: none;
  max-width: 25%;
  outline: none;
`;

export default SettingsMenu;

