import React from 'react';
import LazyLoad from 'react-lazyload';
import { TAG } from 'shared/constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LazyImage = props => {
  const { tag, src, alt } = props;

  return (
    <>
      {tag === TAG.IMG ? (
        <LazyLoadImage src={src} alt={alt} loading="lazy" {...props} />
      ) : (
        <LazyLoad>
          <div style={{ backgroundImage: `url(${src})`, ...props?.style }} {...props} />
        </LazyLoad>
      )}
    </>
  );
};

export default LazyImage;

