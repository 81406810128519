import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import CategoriesClosed from './Categories/CategoriesClosed';
import CategoriesOpened from './Categories/CategoriesOpened';
import useCheckOutsideClick from '../../../hooks/useCheckOutsideClick';

const RewardCenterMenu = props => {
  const { rewardCenterAssets, setShowProfile, profile } = props;
  const { displayRewardProfileScreen, displayRewardHistoryScreen, displayRewardLoyaltyScreen } = profile;
  const [openCategories, setOpenCategories] = useState(false);
  const closeCategories = () => setOpenCategories(false);
  const { boxRef } = useCheckOutsideClick(closeCategories);

  const displayMyProfile = displayRewardProfileScreen || displayRewardHistoryScreen || displayRewardLoyaltyScreen;
  return (
    <Container>
      <ProfileClickable onClick={() => (displayMyProfile ? setShowProfile(true) : null)}>
        {displayMyProfile && <ProfileIcon src={rewardCenterAssets.REWARD_CENTER_PROFILE_ICON} />}
      </ProfileClickable>
      <CategoriesClickable ref={boxRef} onClick={() => setOpenCategories(!openCategories)}>
        {!openCategories ? (
          <CategoriesClosed rewardCenterAssets={rewardCenterAssets} />
        ) : (
          <CategoriesOpened {...props} />
        )}
      </CategoriesClickable>
    </Container>
  );
};

export default RewardCenterMenu;

const MenuContainerMobilePosition = css`
  bottom: 1rem;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  height: 8rem;
  ${isMobile && MenuContainerMobilePosition}
`;

const ProfileClickable = styled.div`
  position: absolute;
  left: 6.2rem;
  width: 8rem;
  height: 9rem;
  right: 12rem;
  @media only screen and (max-height: 800px) {
    width: 7rem;
    height: 7.5rem;
  }

  @media only screen and (max-width: 859px), only screen and (max-height: 520px) {
    margin-top: 0.8rem;
    width: 5rem;
    height: 5.5rem;
  }
`;

const ProfileIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;

const CategoriesClickable = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 5.2rem;
  width: 34rem;
  margin-top: 3.3rem;

  @media only screen and (max-height: 1000px) {
    margin-top: 3.6rem;
  }

  @media only screen and (max-height: 800px) {
    margin-top: 2.2rem;
  }

  @media only screen and (max-width: 859px), only screen and (max-height: 520px) {
    height: 3.2rem;
    width: 22rem;
    margin-top: 3rem;
  }

  @media only screen and (min-width: 860px) and (max-width: 960px) and (min-height: 521px) {
    left: 2vw;
    height: 4.2rem;
    width: 30rem;
    margin-top: 3.3rem;
  }
  @media only screen and (min-width: 960px) and (max-width: 1150px) and (min-height: 521px) {
    left: calc(50% - 30rem);
  }
`;

