import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import OutOfCoin from './OutOfCoin';
import { connect } from '../../node_modules/react-redux';
import { setPurchaseType as _setPurchaseType, isOutOfCoinsPurchase as _isOutOfCoinsPurchase } from '../../state/actions/prePurchase';
import { initiatePurchase as _initiatePurchase } from '../../state/actions/purchase';
import { assignTransationUUID as _assignTransationUUID } from '../../state/actions/purchase';
var mapStateToProps = function mapStateToProps(state) {
  return _objectSpread({
    outOfCoinsOffer: state.consumables.outOfCoins,
    gameInfo: state.gameInfo,
    coin: state.coin.value
  }, state.playerInfo);
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setPurchaseType: function setPurchaseType(value) {
      dispatch(_setPurchaseType(value));
    },
    isOutOfCoinsPurchase: function isOutOfCoinsPurchase(value) {
      dispatch(_isOutOfCoinsPurchase(value));
    },
    initiatePurchase: function initiatePurchase() {
      dispatch(_initiatePurchase());
    },
    assignTransationUUID: function assignTransationUUID(value) {
      dispatch(_assignTransationUUID(value));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OutOfCoin);