import React from 'react';
import { isTablet } from 'react-device-detect';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import styled from 'styled-components';

const RotateScreen = () => {
  return (
    <PageWrapper>
      <RotateIcon src={assetSource(ASSET_KEY.ROTATE_SCREEN_ROTATE_ICON)} />
      <Title>{getText(TEXT_KEY.ROTATE_SCREEN_TITLE_1)}</Title>
      <Title>{getText(TEXT_KEY.ROTATE_SCREEN_TITLE_2)}</Title>
      <UnlockIcon src={assetSource(ASSET_KEY.ROTATE_SCREEN_UNLOCK_ICON)} />
      <Description>{getText(TEXT_KEY.ROTATE_SCREEN_DESCRIPTION)}</Description>
    </PageWrapper>
  );
};

export default RotateScreen;

const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url(${assetSource(ASSET_KEY.ROTATE_SCREEN_BACKGROUND)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const RotateIcon = styled.img`
  width: 30vw;
  height: ${isTablet ? 19 : 12}vh;
  margin-bottom: 20px;
`;

const Title = styled.p`
  width: 80%;
  white-space: pre-line;
  text-align: center;
  font-size: ${isTablet ? 30 : 22}px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
`;

const UnlockIcon = styled.img`
  width: ${isTablet ? 70 : 50}px;
  height: ${isTablet ? 70 : 50}px;
  margin: 40px 0 20px 0;
`;

const Description = styled.p`
  width: 80%;
  white-space: pre-line;
  text-align: center;
  font-size: ${isTablet ? 20 : 16}px;
  margin: 0;
`;

