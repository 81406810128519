import React from 'react';
import styled from 'styled-components';

const ProgressBarShineAnimation = ({ shineAsset }) => {
  return (
    <Container>
      <ShineImage src={shineAsset} />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  overflow: hidden;
  bottom: 19.4vh;
  left: 5.4vh;
  height: 4.5vh;
  width: 35vh;
  border-radius: 2.2vh;
  z-index: 5;
`;

const ShineImage = styled.img`
  bottom: 19vh;
  margin-left: -70vh;
  height: 4.6vh;
  width: 36vh;
  z-index: 5;

  animation-duration: 6500ms;
  animation-name: progressBarShine;
  animation-iteration-count: infinite;

  @keyframes progressBarShine {
    0% {
      transform: translateX(0);
    }

    30% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(70vh);
    }

    52% {
      transform: translateX(70vh);
    }

    72% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(0);
    }
  }
`;

export default ProgressBarShineAnimation;

