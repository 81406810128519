import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { Spinner } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import Screens from 'shared/screens';
import ImageBW from '../../components/ImageBoundsWrapper';
import SVideoAds from 'shared/screens/VideoAds';
import audio from 'shared/utils/audio';
import Utils from 'shared/utils/index';
import Button from '../../components/Button/Button';
import ThemeContext from 'shared/context/ThemeContext';
import theme from 'shared/assets/style/theme';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import ModalHeader from './components/WatchAndEarnHeader';
import { DialogheaderContainerStyle, DialogHeaderHeight } from './components/styledComponents';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

var pad = require('underscore.string/pad');

export default class VideoAds extends Component {
  state = {
    startedPlaying: false,
    videoProgressPercent: 0,
    videoDuration: 0,
    videoPaused: false,
    showWarningPopUp: false,
    muted: isMobile ? true : false,
    showPlayButton: isMobile ? true : false,
    mediaPlayerReady: isMobile ? false : true,
  };

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-video-ad',
    };
  }

  toggleSound = () => {
    this.setState(prevState => ({
      muted: !prevState.muted,
    }));
  };

  handlePlayButtonClick = () => {
    this.setState({
      showPlayButton: false,
    });
  };

  onMediaPlayerReady = () => {
    this.setState({ mediaPlayerReady: true });
  };

  handleWarning = (value, error = false) => {
    if (error || this.state.showPlayButton) {
      this.props.dismissModal();
      return;
    }
    audio.onClick();
    const { startedPlaying } = this.state;

    this.setState({ showWarningPopUp: value });

    if (startedPlaying) {
      this.setState({ videoPaused: value });
    }
  };

  handleVideoStarted = () => {
    const { showWarningPopUp } = this.state;

    this.setState({
      startedPlaying: true,
      videoPaused: showWarningPopUp,
    });
  };

  onDuration(duration) {
    if (this.state.videoDuration !== duration) {
      this.setState({ videoDuration: duration });
    }
  }

  onProgress(evt) {
    if (this.state.paused || evt.played === undefined || evt.played === null) {
      return;
    }

    this.setState({ videoProgressPercent: evt.played });
  }

  getTimeString(time) {
    return parseInt(time / 60) + ':' + pad(parseInt(time % 60), 2, '0');
  }

  render() {
    const themeContext = this.context;
    const playing = !this.state.videoPaused && !this.state.showPlayButton;
    return (
      <SVideoAds
        navigation={this.props.navigation}
        render={props => {
          const { url, reward, completed, error, setCompleted, showModal } = props;
          const { startedPlaying } = this.state;
          const barWidth = this.state.videoProgressPercent >= 0.99 ? 1 : this.state.videoProgressPercent;
          return (
            <VideoAdsWrapper>
              <ModalCloseButtonWrapper onClick={() => this.handleWarning(true, error)}>
                <ImageBW
                  source={assetSource(ASSET_KEY.CLOSE_BUTTON)}
                  ibw={require(asset`close-button.ibw`)}
                  style={Styles.closButton}
                  resizeMode="contain"
                />
              </ModalCloseButtonWrapper>
              <div className="videoAdsContainer">
                {this.state.showPlayButton && this.state.mediaPlayerReady && (
                  <PlayButtonWrapper>
                    <PlayerImageBtn
                      alt={getText(TEXT_KEY.BUTTON_PLAY)}
                      src={assetSource(ASSET_KEY.PLAY_ON_ICON)}
                      onClick={this.handlePlayButtonClick}
                      label={''}
                    />
                  </PlayButtonWrapper>
                )}

                <div className="videoWrapper">
                  <div className="wrapper">
                    <ReactPlayer
                      url={url}
                      playing={playing}
                      onStart={this.handleVideoStarted}
                      onEnded={setCompleted}
                      onDuration={this.onDuration.bind(this)}
                      onProgress={this.onProgress.bind(this)}
                      allowFullScreen={false}
                      playsinline={true}
                      onReady={this.onMediaPlayerReady}
                      muted={this.state.muted}
                      width="100%"
                      height="100%"
                      className="player"
                      style={this.state.videoProgressPercent !== 0 ? {} : { visibility: 'hidden' }}
                    />
                  </div>
                  {(reward.amount === 0 || !startedPlaying || this.state.videoProgressPercent === 0) && (
                    <Spinner animation="border" style={Styles.spinner} />
                  )}
                </div>
                <div className="progressArea">
                  <div className="timeArea">
                    <span className="videoTime">
                      {this.getTimeString(this.state.videoDuration * this.state.videoProgressPercent) +
                        ' / ' +
                        this.getTimeString(this.state.videoDuration)}
                    </span>
                  </div>
                  <div className="barArea">
                    <div className="backerContainer">
                      <div className="barBacker">
                        <div className="barFill" style={{ width: barWidth * 100 + '%' }}></div>
                      </div>
                      {isMobile && (
                        <SoundToggleWrapper onClick={this.toggleSound}>
                          <SoundToggoleImage
                            alt={getText(TEXT_KEY.MUSIC)}
                            src={this.state.muted ? assetSource(ASSET_KEY.SOUND_OFF) : assetSource(ASSET_KEY.SOUND_ON)}
                          />
                        </SoundToggleWrapper>
                      )}
                    </div>
                  </div>
                </div>

                {completed && showModal(Screens.Congrats)}
                {this.state.showWarningPopUp && (
                  <div className="warningOverlay">
                    <div className="warning">
                      <ImageBW
                        style={Styles.modalDialogWrapper}
                        source={assetSource(ASSET_KEY.DIALOG)}
                        ibw={require(asset`dialog.ibw`)}
                      >
                        <ResultModalContainer>
                          <ModalHeader
                            headerHeightStyle={DialogHeaderHeight}
                            containerStyle={DialogheaderContainerStyle}
                            title={getText(TEXT_KEY.ARE_YOU_SURE)}
                          />
                          <div className={'warningMessage' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
                            {getText(TEXT_KEY.WILL_NOT_RECEIVE_COINS)}
                          </div>
                          <div className={'warningButton' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
                            <Button
                              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_DANGER)}
                              containerStyle={Styles.dialogButtonContainerStyle}
                              imageStyle={Styles.dialogButtonImageStyle}
                              textStyle={themeContext.VideoAds.CloseText}
                              label={getText(TEXT_KEY.CLOSE_VIDEO_MODAL)}
                              onClick={this.props.dismissModal}
                              textStroke={BUTTON_STROKES.DANGER}
                            />
                            <Button
                              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                              imageStyle={Styles.dialogButtonImageStyle}
                              containerStyle={Styles.dialogButtonContainerStyle}
                              textStroke={BUTTON_STROKES.PRIMARY}
                              textStyle={themeContext.VideoAds.KeepWatchingText}
                              onClick={() => this.handleWarning(false)}
                              label={getText(TEXT_KEY.KEEP_WATCHING)}
                            />
                          </div>
                        </ResultModalContainer>
                      </ImageBW>
                    </div>
                  </div>
                )}
                {error && (
                  <div className="warningOverlay">
                    <div className="warning">
                      <ImageBW
                        style={Styles.modalDialogWrapper}
                        source={assetSource(ASSET_KEY.DIALOG)}
                        ibw={require(asset`dialog.ibw`)}
                      >
                        <ErrorModalContainer>
                          <ModalHeader
                            headerHeightStyle={DialogHeaderHeight}
                            containerStyle={DialogheaderContainerStyle}
                            title={getText(TEXT_KEY.VIDEO_ERROR)}
                          />
                          <div className={'warningMessage' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
                            {getText(TEXT_KEY.DEFAULT_ERROR_MESSAGE)}
                          </div>
                          <div className={'warningButton' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
                            <Button
                              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_DANGER)}
                              containerStyle={Styles.dialogButtonContainerStyle}
                              imageStyle={Styles.dialogButtonImageStyle}
                              label={getText(TEXT_KEY.CLOSE_VIDEO_MODAL)}
                              onClick={this.props.dismissModal}
                              textStroke={theme.palette.secondary[34]}
                            />
                          </div>
                        </ErrorModalContainer>
                      </ImageBW>
                    </div>
                  </div>
                )}
              </div>
            </VideoAdsWrapper>
          );
        }}
      />
    );
  }
}

VideoAds.hideCloseButton = true;
VideoAds.contextType = ThemeContext;

const VideoAdsWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 2;
`;

const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: -1.75rem;
  right: -2rem;
  padding: 0;
  height: 4rem;
  width: 4rem;
  z-index: 9;
  cursor: pointer;
  pointer-events: auto;
`;

const ResultModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

const PlayButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const PlayerImageBtn = styled.img`
  width: 6rem;
  height: 6rem;
  object-fit: contain;
  margin: 1rem;
`;

const SoundToggleWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  right: 0px;
  width: 3rem;
  height: 100%;
  border-bottom-right-radius: 1em;
  background-color: ${theme.palette.common[3]};
  z-index: 2;
`;

const SoundToggoleImage = styled.img`
  height: 100%;
  padding: 0.18rem;
  width: 2rem;
  object-fit: contain;
`;

const ErrorModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

const Styles = {
  playButtonContainerStyle: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    height: '4em',
    width: '12em',
    zIndex: 1000,
  },
  dialogButtonContainerStyle: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    alignSelf: 'center',
    height: '3em',
    width: '11em',
  },
  dialogButtonImageStyle: {
    width: '100%',
    height: '100%',
  },
  closButton: {
    width: '100%',
    height: '100%',
  },
  modalDialogWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  spinner: {
    position: 'absolute',
  },
};

