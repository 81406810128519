import React, { useState, useRef, useEffect, useMemo } from 'react';
import Utils from 'shared/utils';
import { generateGameListData } from 'shared/components/GamePage/utils';
import GameTile from '../GameTile';
import CustomScrollBar from '../CustomScrollBar';
import { MainContainer, getScrollBarConfig } from './styles';
import './scrollBarStyles.scss';
import Banner from '../Banner/Banner';
import { checkIfBannersEnabled } from 'shared/components/GamePage/utils';

const Home = props => {
  const { pagedGames, pageNumber, navigation, ftueStep, gameIndex, hasPromotions, lobbyBanners, lobbyPromotions } =
    props;

  const [scrolling, setScrolling] = useState(false);

  const gameTileRef = useRef(null);

  const { gameList, totalGamesCount } = useMemo(() => {
    return generateGameListData(pagedGames, pageNumber, ftueStep);
  }, [pagedGames, pageNumber, ftueStep]);

  const lobbyPromotionsLength = lobbyPromotions?.length;
  const bannersExist = lobbyBanners?.length > 0;
  const showBanner = checkIfBannersEnabled(bannersExist);

  return (
    <CustomScrollBar
      onScrollCallback={() => setScrolling(!scrolling)}
      {...getScrollBarConfig(ftueStep, gameIndex, hasPromotions, totalGamesCount, showBanner, lobbyPromotionsLength)}
    >
      <MainContainer>
        {showBanner && <Banner navigation={navigation} lobbyBanners={lobbyBanners} scrolling={scrolling} />}
        {gameList.map((panelList, indexPaged) => {
          return (
            <div className="live-panel-grid" key={indexPaged}>
              {!Utils.isEmpty(panelList) &&
                panelList.map((item, index) => {
                  return (
                    item && (
                      <GameTile
                        gameTileRef={gameTileRef}
                        highlightedForOnboarding={index === 0 && indexPaged === 0}
                        itemData={item}
                        key={index}
                        navigation={navigation}
                      />
                    )
                  );
                })}
            </div>
          );
        })}
      </MainContainer>
    </CustomScrollBar>
  );
};

export default Home;

